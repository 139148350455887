import React, {
  useEffect, useState
} from 'react'
import { Link } from 'react-router-dom'
import {
  type AlertColor, Box, Button, Container, Divider, Grid, Typography, Hidden,
} from '@mui/material'
import {
  AssignmentInd, CheckCircle, NavigateNext, SupportAgent
} from '@mui/icons-material'
import TagManager from 'react-gtm-module'
import {
  colors,
  mobileHeroHeight,
} from '../../styles'
import MapBox from '../../components/Map'
import {
  type BookingContextProps, DurationTypeTitles
} from '../../utils/data'
import InfoBox from '../../components/InfoBox'
import {
  callSMProxyApiCreateCustomerWithReservation,
  type SMProxyCreateCustomerWithReservationDataProps,
  type SMProxyCreateCustomerWithReservationResponseDataProps,
  type SMProxyResponseProps
} from '../../utils/apis'
import Loading from '../../components/Loading'
import IconWithText from '../../components/IconWithText'
import { CTA } from '../../utils/commonStyling'

const Booking = () => {
  const [
    state,
    setState
  ] = useState<BookingContextProps>()

  const [
    loading,
    setLoading,
  ] = useState(true)

  const [
    message,
    setMessage,
  ] = useState({
    active: false,
    severity: 'error' as AlertColor,
    title: 'Message title',
    text: 'Message sample text'
  })

  useEffect(() => {
    const storedBookingRaw = localStorage.getItem('booking')

    if (storedBookingRaw === null) {
      setMessage({
        active: true,
        severity: 'warning',
        title: 'Booking Data Not Found',
        text: 'We were unable to locate your booking details on this browser\'s local storage. ' +
          'This does not mean that your booking has failed, ' +
          'but rather that you have probably hit "refresh" after your booking has been confirmed.' +
          'If you need further assistance please get in touch with our support.'
      })
      setLoading(false)
      return
    }

    const storedBooking = JSON.parse(storedBookingRaw) as BookingContextProps
    storedBooking.startDate = new Date(storedBooking.startDate as string)

    const urlSearchParameters = new URLSearchParams(window.location.search)
    const parameters: Record<string, string> = {}

    // Add payment ref to the stored booking data in case it is available in the URL
    for (const [
      key,
      value
    ] of urlSearchParameters.entries()) {
      parameters[key] = value
    }

    // Disabe payment check ref for now
    // if (parameters.payment_intent) {
    //   storedBooking.stripeRef = parameters.payment_intent
    // } else {
    //   setMessage({
    //     active: true,
    //     severity: 'error',
    //     title: 'Payment Confirmation Not Found',
    //     text: 'Payment confirmation reference is missing in this link. Please go back and try again or contact our support.'
    //   })
    //   setLoading(false)
    //   return
    // }

    setState(storedBooking)
  }, [])

  useEffect(() => {
    // Only proceed if we have aa state
    if (state !== undefined) {
      // If we do not have a booking reference, try to reserve the unit,
      // otherwise store the reference in local storage in order not to double book
      if (state.bookingRef === undefined) {
        if (
          // State.stripeRef === undefined || We have disabled this as we have disabled stripe Payment
          state.startDate === undefined ||
          state.confirmedLocation === undefined ||
          state.confirmedSize === undefined
        ) {
          setMessage({
            active: true,
            severity: 'error',
            title: 'Booking Data Missing',
            text: 'Some important data is missing from your local storage. ' +
              'Please hit "refresh" and try again or get in touch with our support.'
          })
          setLoading(false)
          return
        }

        const data: SMProxyCreateCustomerWithReservationDataProps = {
          site_id: state.confirmedLocation.siteId,
          title: state.title,
          first_name: state.firstName,
          last_name: state.lastName,
          address_line_1: state.address.address,
          town: state.address.town,
          county: state.address.county,
          postcode: state.address.postcode,
          move_in_date: state.startDate,
          opt_in: true,
          email: state.email,
          phone_number: `${state.phoneCountryCode.code}${state.phone ?? ''}`,
          size: state.confirmedSize.size,
          size_code_id: state.confirmedSize.size_code_id,
          deposit_amount: state.confirmedSize?.discounted_week_rate ?? state.confirmedSize?.standard_week_rate,
          stripe_payment_ref: 'NOT-AVAILABLE', // State.stripeRef, TEMPORARY DISABLE PAYMENT CHECKS
          site_photo_url: `${window.location.protocol}//${window.location.host}${state.confirmedLocation?.image ?? ''}`,
          unit_size: state.confirmedSize.physical_size,
          duration: DurationTypeTitles[state.duration?.toString() as keyof typeof DurationTypeTitles],
          price_per_week: state.confirmedSize?.standard_week_rate,
          special_price_per_week: state.confirmedSize?.discounted_week_rate ?? state.confirmedSize?.standard_week_rate,
          location_search_text: state.searchText
        }

        // Try to post the order
        callSMProxyApiCreateCustomerWithReservation(state.confirmedLocation.siteId, data)
          .then((response: SMProxyResponseProps<SMProxyCreateCustomerWithReservationResponseDataProps>) => {
            if (response.error) {
              if (response.error === 'Invalid phone number') {
                setMessage({
                  active: true,
                  severity: 'error',
                  title: 'Invalid Phone Number',
                  text: 'Please go back and enter a valid phone number and then try again.'
                })
              } else {
                console.log(response.error)
                setMessage({
                  active: true,
                  severity: 'error',
                  title: 'Unit Reservation Failed',
                  text: 'Failed to book your unit due to technical issues. ' +
                    'Please wait a few minutes and hit "refresh" to try again!'
                })
              }

              setLoading(false)
              return
            }

            if (response.data === undefined || response.data.reservation_id === undefined) {
              setMessage({
                active: true,
                severity: 'error',
                title: 'Unit Reservation Failed',
                text: 'Failed to book your unit due to technical issues. ' +
                  'Please wait a few minutes and hit "refresh" to try again!'
              })
              setLoading(false)
              return
            }

            // Store the reservation id
            setState(previousState => ({
              ...previousState!,
              bookingRef: response.data?.reservation_id
            }))

            setLoading(false)
          })
          .catch(error => {
            console.error(error)
            setMessage({
              active: true,
              severity: 'error',
              title: 'Unit Reservation Failed',
              text: 'Failed to book your unit due to technical issues. ' +
                'Please wait a few minutes and hit "refresh" to try again!'
            })
            setLoading(false)
          })
      } else {
        localStorage.setItem('booking', JSON.stringify(state))
        setLoading(false)
        TagManager.dataLayer({
          dataLayer: {
            event: 'quote_confirmation',
            location: state.confirmedLocation?.name
          }
        })
      }
    }
  }, [state])

  return (
    <>
      {loading && <Loading fullscreen/>}
      {message.active &&
        <Container maxWidth="md" sx={{ py: 3 }}>
          <IconWithText
            title={message.title}
            text={message.text}
            severity={message.severity}
          />
        </Container>}
      {!loading && state && state.bookingRef &&
        <Box display="flex" flexDirection="column-reverse">
          <Container
            sx={{
              pb: 5,
              pt: 7
            }}
          >
            <Grid container spacing={16} height="100%">
              <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyItems="center">
                <Typography variant="h1" gutterBottom>
                  {state.confirmedSize?.is_locker ?
                    <>
                      Your <strong>locker self-storage</strong> at <strong>{state.confirmedLocation?.name}</strong> has been booked successfully
                    </>
                    :
                    <>
                      Your <strong>{state.confirmedSize?.size} ft<sup>2</sup> self-storage</strong> at <strong>{state.confirmedLocation?.name}</strong> has been booked successfully
                    </>}
                </Typography>
                <Box display="flex" gap={1} alignItems="center">
                  <CheckCircle color="success"/>
                  <Typography variant="body1">Confirmation email has been sent
                    to <strong>{state.email}</strong>.
                  </Typography>
                </Box>
                <Box display="flex" gap={1} mt={2} alignItems="center">
                  <SupportAgent color="info"/>
                  <Typography variant="body1">A member of the team will be in touch shortly.
                  </Typography>
                </Box>
                <Divider sx={{ my: 5 }}/>
                <Typography variant="body1" sx={{ mb: 3 }}>
                  We will be expecting you on <strong>{ (state.startDate as Date).toDateString() }</strong> at
                  {' '}
                  <strong>{state.confirmedLocation?.address}</strong>.
                </Typography>
                <InfoBox icon={<AssignmentInd/>}>
                  Please bring one photo ID and one proof of address.
                </InfoBox>
                <Hidden mdDown>
                  <Divider sx={{ my: 5 }}/>
                </Hidden>
                <Box
                  mt={{
                    xs: 3,
                    md: 0
                  }}
                >
                  <Link to="https://www.vanguardstorage.co.uk/">
                    <Button
                      variant="contained"
                      size="large"
                      endIcon={<NavigateNext/>}
                      {...CTA}
                    >
                      Return to home
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Box
            position={{
              sm: 'static',
              md: 'absolute'
            }}
            top="0"
            left="50vw"
            width={{
              xs: '100vw',
              md: '50vw'
            }}
            height={{
              xs: mobileHeroHeight,
              md: '100vh'
            }}
            bgcolor={colors.neutral[100]}
            boxSizing="border-box"
          >
            <MapBox
              zoom={15}
              center={state.confirmedLocation?.coordinates ?? [
                0,
                0
              ]}
              height="100%"
              width="100%"
              centerPin={state.confirmedLocation}
            />
          </Box>
        </Box>}
    </>
  )
}

export default Booking
