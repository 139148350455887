import React, {
  useContext
} from 'react'
import {
  useNavigate
} from 'react-router-dom'
import {
  Box, Container, Typography,
} from '@mui/material'
import { Edit } from '@mui/icons-material'
import TagManager from 'react-gtm-module'
import {
  type LocationProps, type BookingContextProps, type SizeProps
} from '../../utils/data'
import BookingContext from '../../components/BookingContext'
import UnitLarge from '../../components/UnitLarge'
import InfoBox from '../../components/InfoBox'
import {
  CONTAINER, LISTER,
} from '../../utils/commonStyling'

type LocationAndSizeType =
{
  location: LocationProps;
  size: SizeProps;
}

const getOtherOptionsAtCurrentLocation = (booking: BookingContextProps): LocationAndSizeType[] => {
  const otherLocationOptions: LocationAndSizeType[] = []

  if (booking.selectedLocation === undefined || booking.selectedLocation.sizes === undefined) {
    return otherLocationOptions
  }

  let nextSmallerAvailableSize: SizeProps | undefined
  let nextBiggerAvailableSize: SizeProps | undefined
  for (const size of booking.selectedLocation.sizes) {
    if (
      size.physical_size < (booking.selectedSize?.physical_size ?? 0) &&
        (
          nextSmallerAvailableSize === undefined ||
            size.physical_size > nextSmallerAvailableSize.physical_size
        )
    ) {
      nextSmallerAvailableSize = size
    }

    if (
      size.physical_size > (booking.selectedSize?.physical_size ?? 0) &&
        (
          nextBiggerAvailableSize === undefined ||
            size.physical_size < nextBiggerAvailableSize.physical_size
        )
    ) {
      nextBiggerAvailableSize = size
    }
  }

  if (nextSmallerAvailableSize !== undefined) {
    otherLocationOptions.push({
      location: booking.selectedLocation,
      size: nextSmallerAvailableSize
    })
  }

  if (nextBiggerAvailableSize !== undefined) {
    otherLocationOptions.push({
      location: booking.selectedLocation,
      size: nextBiggerAvailableSize
    })
  }

  return otherLocationOptions
}

const getNearbyLocationOptions = (booking: BookingContextProps): LocationAndSizeType[] => {
  const nearbyLocationOptions: LocationAndSizeType[] = []

  if (booking.foundLocations === undefined) {
    return nearbyLocationOptions
  }

  for (const location of booking.foundLocations) {
    // We would want to ignore the current location as we would like to find locations nearby
    if (location === booking.selectedLocation) {
      continue
    }

    // First check if we have the same size in this other location and use it
    const size = location.sizes.find((item: SizeProps) => item.size === booking.selectedSize?.size)
    if (size === undefined) {
      // If we don't have the same location, try to find the closest larger and smaller options
      let nextSmallerAvailableSize: SizeProps | undefined
      let nextBiggerAvailableSize: SizeProps | undefined
      for (const size of location.sizes) {
        if (
          size.physical_size < (booking.selectedSize?.physical_size ?? 0) &&
            (
              nextSmallerAvailableSize === undefined ||
                size.physical_size > nextSmallerAvailableSize.physical_size
            )
        ) {
          nextSmallerAvailableSize = size
        }

        if (
          size.physical_size > (booking.selectedSize?.physical_size ?? 0) &&
            (
              nextBiggerAvailableSize === undefined ||
                size.physical_size < nextBiggerAvailableSize.physical_size
            )
        ) {
          nextBiggerAvailableSize = size
        }
      }

      if (nextSmallerAvailableSize !== undefined) {
        nearbyLocationOptions.push({
          location,
          size: nextSmallerAvailableSize
        })
      }

      if (nextBiggerAvailableSize !== undefined) {
        nearbyLocationOptions.push({
          location,
          size: nextBiggerAvailableSize
        })
      }
    } else {
      nearbyLocationOptions.push({
        location,
        size
      })
    }
  }

  return nearbyLocationOptions
}

const Quote = () => {
  const navigate = useNavigate()
  const booking: BookingContextProps = useContext(BookingContext)

  if (booking.selectedLocation === undefined || booking.selectedSize === undefined) {
    return null
  }

  const selectOption = (location: LocationProps | undefined, size: SizeProps | undefined) => {
    if (location !== undefined && size !== undefined) {
      booking.confirmedLocation = location
      booking.confirmedSize = size
    }
  }

  const selectedLocationSize = booking.selectedLocation.sizes.find(
    (item: SizeProps) => item.size === booking.selectedSize?.size && item.is_locker === booking.selectedSize?.is_locker
  )

  // Select the current location as it has not been set yet
  if (selectedLocationSize && booking.selectedLocation) {
    booking.confirmedLocation = booking.selectedLocation
    booking.confirmedSize = selectedLocationSize
  }

  const otherLocationOptions: LocationAndSizeType[] = getOtherOptionsAtCurrentLocation(booking)
  const nearbyLocationOptions: LocationAndSizeType[] = getNearbyLocationOptions(booking)

  if (!selectedLocationSize && otherLocationOptions.length === 0 && nearbyLocationOptions.length === 0) {
    return (
      <Container {...CONTAINER}>
        <Typography variant="h1" gutterBottom>Storage unavailable</Typography>
        <Typography variant="body1">
          Sorry we do not have any storage available at <strong>{booking.selectedLocation?.name}</strong>.
          Please leave your phone number below and we will get in touch with you.
        </Typography>
      </Container>
    )
  }

  return (
    <Container {...CONTAINER}>
      <InfoBox
        severity="info"
        action={{
          text: 'Edit',
          to: '/storage',
          icon: <Edit/>
        }}
        mb={{
          xs: 4,
          sm: 5
        }}
      >
        {booking.selectedSize.is_locker ? (
          <>
            You are looking for <strong> Locker</strong> at <strong>{booking.selectedLocation.name}</strong>.
          </>
        ) : (
          <>
            You are looking for <strong>{booking.selectedSize.size} ft<sup>2</sup></strong> at <strong>{booking.selectedLocation.name}</strong>.
          </>
        )}
      </InfoBox>
      <Typography variant="h1" gutterBottom>Recommended Unit</Typography>
      {booking.selectedLocation && selectedLocationSize &&
      <Box display="flex" flexDirection="column" gap={6}>
        <UnitLarge
          location={booking.selectedLocation}
          size={selectedLocationSize}
          select={() => {
            selectOption(booking.selectedLocation, selectedLocationSize)
            TagManager.dataLayer({
              dataLayer: {
                event: 'quote_unit_reserve',
                location: booking.selectedLocation?.name
              }
            })
            navigate('/details')
          }}
        />
      </Box>}
      {booking.selectedLocation && !selectedLocationSize && otherLocationOptions.length > 0 &&
        <InfoBox severity="warning" mb={4}>
          Sorry we do not have {booking.selectedSize?.size} ft<sup>2</sup> unit size available at <strong>{booking.selectedLocation?.name}</strong>.
          Please choose from the other options below:
        </InfoBox>}
      {booking.selectedLocation && !selectedLocationSize && otherLocationOptions.length === 0 &&
        <InfoBox severity="warning" mb={4}>
          Sorry we do not have anything available at <strong>{booking.selectedLocation?.name}</strong>.
          Please choose from the other similar options at nearby locations below:
        </InfoBox>}
      {otherLocationOptions.length > 0 &&
      <Box
        mt={{
          xs: 4,
          sm: 7,
        }}
      >
        <Typography variant="h1" gutterBottom>Other options at {booking.selectedLocation?.name}</Typography>
        <Box {...LISTER}>
          {otherLocationOptions.map((locationAndSize: LocationAndSizeType, index: number) => (
            <UnitLarge
              location={locationAndSize.location}
              size={locationAndSize.size}
              select={() => {
                selectOption(locationAndSize.location, locationAndSize.size)
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'quote_unit_reserve',
                    location: locationAndSize.location.name
                  }
                })
                navigate('/details')
              }}
              key={index}
            />
          ))}
        </Box>
      </Box>}
      {nearbyLocationOptions.length > 0 &&
        <Box
          mt={{
            xs: 4,
            sm: 7,
          }}
        >
          <Typography variant="h1" gutterBottom>Other options at locations nearby</Typography>
          <Box {...LISTER}>
            {nearbyLocationOptions.map((locationAndSize: LocationAndSizeType, index: number) => (
              <UnitLarge
                location={locationAndSize.location}
                size={locationAndSize.size}
                select={() => {
                  selectOption(locationAndSize.location, locationAndSize.size)
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'quote_unit_reserve',
                      location: locationAndSize.location.name
                    }
                  })
                  navigate('/details')
                }}
                key={index}
              />
            ))}
          </Box>
        </Box>}
    </Container>
  )
}

export default Quote
