import React, {
  type MouseEvent, useContext, useState, useRef
} from 'react'
import {
  Event, DateRange, LocationOn, ZoomOutMap, ArrowForward
} from '@mui/icons-material'
import {
  type AlertColor,
  Box, Button, Container, Grid, Paper, Typography, Stack
} from '@mui/material'
import {
  Link, useNavigate
} from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  type BookingContextProps, DurationTypeTitles
} from '../../utils/data'
import BookingContext from '../../components/BookingContext'
import InfoBox from '../../components/InfoBox'
import Loading from '../../components/Loading'
import IconWithText from '../../components/IconWithText'
import {
  callSMProxyApiConfirmReservation, type SMProxyConfirmReservationDataProps,
  type SMProxyConfirmReservationResponseProps, type SMProxyResponseProps
} from '../../utils/apis'
import {
  BOX, CONTAINER, CTA
} from '../../utils/commonStyling'
import { palette } from '../../styles'

const OrderSummary = () => {
  const booking: BookingContextProps = useContext(BookingContext)
  const reCaptchaRef = useRef<ReCAPTCHA>(null)
  const navigate = useNavigate()

  const [
    loading,
    setLoading,
  ] = useState(false)

  const [
    message,
    setMessage,
  ] = useState({
    active: false,
    severity: 'error' as AlertColor,
    title: 'Message title',
    text: 'Message sample text'
  })

  if (
    booking.confirmedLocation === undefined ||
    booking.confirmedSize === undefined ||
    booking.startDate === undefined ||
    booking.duration === undefined
  ) {
    return null
  }

  const handleContinue = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (reCaptchaRef.current === null) {
      setMessage({
        active: true,
        title: 'Recaptcha Failed',
        text: 'We could not verify that you are not a robot. Please try again later.',
        severity: 'error' as AlertColor
      })
      return
    }

    // First do the recaptcha before sending the request to the server
    reCaptchaRef.current.executeAsync()
      .then(token => {
        setLoading(true)

        const data: SMProxyConfirmReservationDataProps = {
          site_id: booking.confirmedLocation?.siteId ?? 'unknown',
          title: booking.title,
          first_name: booking.firstName,
          last_name: booking.lastName,
          address_line_1: booking.address.address,
          town: booking.address.town,
          county: booking.address.county,
          postcode: booking.address.postcode,
          move_in_date: booking.startDate,
          opt_in: false,
          email: booking.email,
          phone_number: `${booking.phoneCountryCode.code}${booking.phone ?? ''}`,
          size: booking.confirmedSize?.size,
          size_code_id: booking.confirmedSize?.size_code_id,
          deposit_amount: booking.confirmedSize?.discounted_week_rate ?? booking.confirmedSize?.standard_week_rate,
          stripe_payment_ref: booking.stripeRef,
          token: token ?? undefined
        }

        callSMProxyApiConfirmReservation(data)
          .then((response: SMProxyResponseProps<SMProxyConfirmReservationResponseProps>) => {
            if (response.error ?? !response.data?.success) {
              if (response.error) {
                console.error(response.error)
              } else {
                console.error(response.data?.message)
              }

              setMessage({
                active: true,
                title: 'Reservation Confirmation Failed',
                text: 'Failed to confirm your reservation due to technical difficulties. Please try again later.',
                severity: 'error' as AlertColor
              })
              setLoading(false)
              return
            }

            // This is added before going to booking because this is how stripe passes data to the booking.
            // If we resume the prepayment step, we will have to remove this line.
            localStorage.setItem('booking', JSON.stringify(booking))

            navigate('/booking')
          })
          .catch(error => {
            console.error(error)
            setMessage({
              active: true,
              title: 'Reservation Confirmation Failed',
              text: 'Failed to confirm your reservation due to technical difficulties. Please try again later.',
              severity: 'error' as AlertColor
            })
            setLoading(false)
          })

        // Reset the reCAPTCHA for subsequent use
        reCaptchaRef.current?.reset()
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        setMessage({
          active: true,
          title: 'Recaptcha Failed',
          text: 'We could not verify that you are not a robot. Please try again later.',
          severity: 'error' as AlertColor
        })
      })
  }

  return (
    <>
      {loading && <Loading fullscreen/>}
      {message.active &&
      <Container {...CONTAINER}>
        <IconWithText
          title={message.title}
          text={message.text}
          severity={message.severity}
        />
      </Container>}
      {!loading && !message.active &&
        <Container {...CONTAINER}>
          <Typography variant="h1">Reservation Summary</Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 1.5,
              mb: 6
            }}
          >
            Please check if the details below are correct and click &quot;Confirm &amp; Reserve&quot; button at the bottom of the page.
          </Typography>
          <Stack gap={3}>
            <Box
              component={Paper}
              variant="outlined"
              {...BOX}
            >
              <Typography variant="h4" gutterBottom>Storage Details</Typography>
              <Grid
                container
                spacing={{
                  xs: 3,
                  sm: 4,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>Location</Typography>
                  <Typography variant="body1" display="flex" gap={1} alignItems="center" className="static">
                    <LocationOn color="secondary" fontSize="small"/>
                    <span>{booking.confirmedLocation?.address}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>Estimated start date</Typography>
                  <Typography variant="body1" display="flex" gap={1} alignItems="center" className="static">
                    <Event color="secondary" fontSize="small"/>
                    <span>{booking.startDate instanceof Date ? booking.startDate.toDateString() : '-'}</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>Unit size</Typography>
                  <Typography variant="body1" display="flex" gap={1} alignItems="center" className="static">
                    <ZoomOutMap color="secondary" fontSize="small"/>
                    <span>
                      {booking.confirmedSize?.is_locker ?
                        <>
                          Locker
                        </>
                        :
                        <>
                          {booking.confirmedSize?.physical_size} ft<sup>2</sup>
                        </>}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>Duration</Typography>
                  <Typography variant="body1" display="flex" gap={1} alignItems="center" className="static">
                    <DateRange color="secondary" fontSize="small"/>
                    <span>{DurationTypeTitles[booking.duration.toString() as keyof typeof DurationTypeTitles]}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              component={Paper}
              variant="outlined"
              {...BOX}
            >
              <Typography variant="h4" gutterBottom>Your Details</Typography>
              <Grid
                container
                spacing={{
                  xs: 3,
                  sm: 4,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>Full name</Typography>
                  <Typography variant="body1" className="static">{booking.title} {booking.firstName} {booking.lastName}</Typography>
                </Grid>
                {booking.phone !== undefined &&
                  <Grid item xs={12} sm={6}>
                    <Typography variant="caption" gutterBottom>Mobile phone</Typography>
                    <Typography variant="body1" className="static">
                      <Link to={`tel:${booking.phone}`} style={{ color: palette.primary.main }}>{booking.phoneCountryCode.code} {booking.phone}</Link>
                    </Typography>
                  </Grid>}
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>Address</Typography>
                  <Typography variant="body1" className="static">{booking.address.fullAddress}</Typography>
                </Grid>
                {booking.email !== undefined &&
                  <Grid item xs={12} sm={6}>
                    <Typography variant="caption" gutterBottom>Email</Typography>
                    <Typography variant="body1" className="static">
                      <Link to={`mailto:${booking.email}`} style={{ color: palette.primary.main }}>{booking.email}</Link>
                    </Typography>
                  </Grid>}
              </Grid>
            </Box>
            <Box component={Paper} variant="outlined" {...BOX}>
              <Typography variant="h4" gutterBottom>Quote Details</Typography>
              <Grid
                container
                spacing={{
                  xs: 3,
                  sm: 4,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>Price per week</Typography>
                  <Typography variant="body1" className="static">&pound;{booking.confirmedSize?.standard_week_rate?.toFixed(2)} (inc. VAT)</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption" gutterBottom>Reservation pre-payment (first week)</Typography>
                  <Typography variant="body1" className="static">&pound;{booking.confirmedSize?.discounted_week_rate?.toFixed(2) ?? booking.confirmedSize?.standard_week_rate?.toFixed(2)} (inc. VAT)</Typography>
                </Grid>
                {booking.confirmedSize?.discounted_week_rate &&
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="primary" gutterBottom>Price per week for the first 8
                      weeks
                    </Typography>
                    <Typography variant="body1" className="static">
                      &pound;{booking.confirmedSize?.discounted_week_rate?.toFixed(2)} (inc. VAT)
                    </Typography>
                  </Grid>}
              </Grid>
              <Typography
                component="div"
                variant="caption"
                sx={{
                  mt: 3
                }}
              >
                *All prices are VAT inclusive.
              </Typography>
              <InfoBox iconPos="top" mt={3}>
                Please note that all goods within the store must be insured.
                You will either need to be covered under Vanguards own insurance policy or provide us with a copy of your own insurance arrangements.
                Our cover starts from &pound;{booking.confirmedLocation?.insuranceStartingPrice?.toFixed(2)} per week.
                {booking.confirmedLocation?.padlockRequired &&
                  <>
                    {' '}You will also need a padlock for your unit. You are welcome to bring your own or we sell them for &pound;10.00.
                  </>}
              </InfoBox>
            </Box>
          </Stack>
          <Box mt={5}>
            <Button variant="contained" color="primary" endIcon={<ArrowForward/>} onClick={handleContinue} size="large" {...CTA}>
              Confirm &amp; Reserve
            </Button>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!}
              size="invisible"
              ref={reCaptchaRef}
            />
          </Box>
        </Container>}
    </>
  )
}

export default OrderSummary
